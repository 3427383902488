/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { getDateObject, getShiftDateForCompany, setCookie } from '../helpers/common';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const apis = {
  _url: process.env.REACT_APP_BASE_API_URL,
  GetOptions(type) {
    const [options, setOptions] = useState({});
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getOptions(type))
        .then(res => {
          setOptions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [type]);
    return {
      options_loading: status === STATUS.LOADING,
      options_error: status === STATUS.ERROR ? status : '',
      options,
    };
  },
  GetUsers(searchQuery = {}, refetch = false) {
    const [users, setHistory] = useState({
      users: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUsers(searchQuery))
        .then(res => {
          setHistory(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.page,
      searchQuery?.itemsPerPage,
      searchQuery?.searchText,
      searchQuery?.filterText,
      searchQuery?.starDate,
      searchQuery?.endDate,
    ]);
    return {
      users_loading: status === STATUS.LOADING,
      users_error: status === STATUS.ERROR ? status : '',
      users_data: users,
    };
  },
  GetCompanies(searchQuery = {}, refetch = false) {
    const [companies, setHistory] = useState({
      companies: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCompanies(searchQuery))
        .then(res => {
          setHistory(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery?.page,
      searchQuery?.itemsPerPage,
      searchQuery?.searchText,
      searchQuery?.filterText,
      searchQuery?.starDate,
      searchQuery?.endDate,
    ]);
    return {
      companies_loading: status === STATUS.LOADING,
      companies_error: status === STATUS.ERROR ? status : '',
      companies_data: companies,
    };
  },
  GetPermissions(searchQuery, refetch) {
    const [permissions, setPermissions] = useState({
      totalItems: 0,
      permissions: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPermissions(searchQuery))
        .then(res => {
          if (searchQuery.parentOnly) {
            setPermissions(() => ({
              permissions: res.permissionStatus,
              totalItems: res.length,
            }));
          } else setPermissions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.parentOnly,
      searchQuery?.filterPermission,
      refetch,
    ]);
    return {
      permissions_loading: status === STATUS.LOADING,
      permissions_error: status === STATUS.ERROR ? status : '',
      permissions_data: permissions,
    };
  },
  GetRoles(searchQuery, refetch) {
    const [roles, setRoles] = useState({
      totalItems: 0,
      roles: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getRoles(searchQuery))
        .then(res => {
          setRoles(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      roles_loading: status === STATUS.LOADING,
      roles_error: status === STATUS.ERROR ? status : '',
      roles_data: roles,
    };
  },
  GetComments(searchQuery, refetch) {
    const [comments, setComments] = useState({
      totalItems: 0,
      comments: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getComments(searchQuery))
        .then(res => {
          setComments(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);

    return {
      comments_loading: status === STATUS.LOADING,
      comments_error: status === STATUS.ERROR ? status : '',
      comments_data: comments,
    };
  },
  GetEspeciales(searchQuery, refetch) {
    const [especiales, setEspeciales] = useState({
      totalItems: 0,
      especiales: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getEspeciales(searchQuery))
        .then(res => {
          setEspeciales(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);

    return {
      especiales_loading: status === STATUS.LOADING,
      especiales_error: status === STATUS.ERROR ? status : '',
      especiales_data: especiales,
    };
  },
  GetEmbalaje(searchQuery, refetch) {
    const [embalaje, setEmbalaje] = useState({
      totalItems: 0,
      embalaje: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getEmbalajes(searchQuery))
        .then(res => {
          setEmbalaje(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);

    return {
      embalaje_loading: status === STATUS.LOADING,
      embalaje_error: status === STATUS.ERROR ? status : '',
      embalaje_data: embalaje,
    };
  },
  GetShifts(searchQuery, refetch) {
    const [shifts, setShifts] = useState({
      totalItems: 0,
      shifts: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getShifts(searchQuery))
        .then(res => {
          setShifts(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterMachine,
      searchQuery?.filterTurno,
      refetch,
    ]);

    return {
      shifts_loading: status === STATUS.LOADING,
      shifts_error: status === STATUS.ERROR ? status : '',
      shifts_data: shifts,
    };
  },
  GetStoppages(searchQuery, refetch) {
    const [stoppages, setStoppages] = useState({
      totalItems: 0,
      stoppages: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getStoppages(searchQuery))
        .then(res => {
          setStoppages(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterMotive,
      searchQuery?.filterMachine,
      searchQuery?.filterTurno,
      searchQuery?.hideMicro,
      refetch,
    ]);

    return {
      stoppages_loading: status === STATUS.LOADING,
      stoppages_error: status === STATUS.ERROR ? status : '',
      stoppages_data: stoppages,
    };
  },
  GetLiveStoppages(comapny_name, refetch, timings) {
    const [stoppages, setStoppages] = useState({
      totalItems: 0,
      stoppages: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLiveStoppages(comapny_name, timings))
        .then(res => {
          setStoppages(() => ({
            ...res,
            stoppages: res.stoppages.sort((a, b) => {
              const { Fecha_y_Hora: _ } = a;
              const { Fecha_y_Hora: __ } = b;
              return new Date(__) - new Date(_);
            }),
          }));
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [refetch, comapny_name]);

    return {
      stoppages_loading: status === STATUS.LOADING,
      stoppages_error: status === STATUS.ERROR ? status : '',
      stoppages_data: stoppages,
    };
  },
  GetLiveShifts(comapny_name, refetch, timings) {
    const [shifts, setShifts] = useState({
      totalItems: 0,
      shifts: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);

      cancellablePromise(this.getLiveShifts(comapny_name, timings))
        .then(res => {
          setShifts(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [refetch, comapny_name]);

    return {
      shifts_loading: status === STATUS.LOADING,
      shifts_error: status === STATUS.ERROR ? status : '',
      shifts_data: shifts,
    };
  },
  GetDashboardComments(dashboardType, [startDate, endDate], company_name, timings) {
    const [comments, setComments] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      cancellablePromise(this.getDashboardComments(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setComments(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      comments_loading: status === STATUS.LOADING,
      comments_error: status === STATUS.ERROR ? status : '',
      comments_data: comments,
    };
  },
  GetDashboardEmbalaje(dashboardType, [startDate, endDate], company_name, timings) {
    const [embalaje, setEmbalaje] = useState({
      labels: [],
      datasets: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDashboardEmbalajes(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setEmbalaje(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      embalaje_loading: status === STATUS.LOADING,
      embalaje_error: status === STATUS.ERROR ? status : '',
      embalaje_data: embalaje,
    };
  },
  GetDashboardMachines(dashboardType, [startDate, endDate], company_name, timings) {
    const [machines, setMachines] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      cancellablePromise(this.getDashboardMachines(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          res = res.slice().sort((a, b) => {
            const sortPriority = [
              'Soldadora',
              'Centro de Mecanizado 1',
              'Centro de Mecanizado 2',
              'Centro de Corte',
              'Soldadora Mono Cabezal',
            ];
            return sortPriority.indexOf(a.machine) - sortPriority.indexOf(b.machine);
          });

          setMachines(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [dashboardType, startDate, endDate]);

    return {
      machines_loading: status === STATUS.LOADING,
      machines_error: status === STATUS.ERROR ? status : '',
      machines_data: machines,
    };
  },
  GetDashboardMachinesForShifts(dashboardType, [startDate, endDate], company_name, timings) {
    const [machines, setMachines] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      cancellablePromise(this.getDashboardMachinesForShifts(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          res = res.slice().sort((a, b) => {
            const sortPriority = [
              'Soldadora',
              'Centro de Mecanizado 1',
              'Centro de Mecanizado 2',
              'Centro de Corte',
              'Soldadora Mono Cabezal',
            ];
            return sortPriority.indexOf(a.machine) - sortPriority.indexOf(b.machine);
          });

          setMachines(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [dashboardType, startDate, endDate]);

    return {
      machines_loading: status === STATUS.LOADING,
      machines_error: status === STATUS.ERROR ? status : '',
      machines_data: machines,
    };
  },
  GetDashboardStoppages(dashboardType, [startDate, endDate], company_name, timings) {
    const [stoppages, setStoppages] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDashboardStoppages(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setStoppages(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      stoppages_loading: status === STATUS.LOADING,
      stoppages_error: status === STATUS.ERROR ? status : '',
      stoppages_data: stoppages,
    };
  },
  GetDashboardStoppagesForShifts(dashboardType, [startDate, endDate], company_name, timings) {
    const [stoppages, setStoppages] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDashboardStoppagesForShifts(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setStoppages(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      stoppages_loading: status === STATUS.LOADING,
      stoppages_error: status === STATUS.ERROR ? status : '',
      stoppages_data: stoppages,
    };
  },
  GetDashboardEspeciales(dashboardType, [startDate, endDate], company_name, timings) {
    const [especiales, setEspeciales] = useState({
      labels: [],
      datasets: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDashboardEspeciales(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setEspeciales(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      especiales_loading: status === STATUS.LOADING,
      especiales_error: status === STATUS.ERROR ? status : '',
      especiales_data: especiales,
    };
  },
  GetMachineOptions() {
    const [machines, setMachines] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getMachineOptions())
        .then(res => {
          setMachines(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);

    return {
      machines_loading: status === STATUS.LOADING,
      machines_error: status === STATUS.ERROR ? status : '',
      machines_data: machines,
    };
  },
  GetLOGS(searchQuery) {
    const [logs, setLogs] = useState({
      totalItems: 0,
      logs: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLOGS(searchQuery))
        .then(res => {
          setLogs(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.filterRoles,
      searchQuery.filterUsers,
      searchQuery.startDate,
      searchQuery.endDate,
    ]);
    return {
      logs_loading: status === STATUS.LOADING,
      logs_error: status === STATUS.ERROR ? status : '',
      logs_data: logs,
    };
  },
  GetLogistica(searchQuery, refetch) {
    const [logistica, setLogistica] = useState({
      totalItems: 0,
      logistica: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLogistica(searchQuery))
        .then(res => {
          setLogistica(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [
      refetch,
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.searchText,
      searchQuery.startDate,
      searchQuery.endDate,
    ]);
    return {
      logistica_loading: status === STATUS.LOADING,
      logistica_error: status === STATUS.ERROR ? status : '',
      logistica_data: logistica,
    };
  },
  GetDashboardLogistica(dashboardType, [startDate, endDate], company_name, timings) {
    const [logistica, setLogistica] = useState({
      labels: [],
      datasets: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (dashboardType === 'custom') {
        if (!startDate || !endDate) {
          return;
        }
        startDate = format(getDateObject(startDate), 'yyyy-MM-dd');
        endDate = format(getDateObject(endDate), 'yyyy-MM-dd');
      }
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDashboardlogistica(dashboardType, startDate, endDate, company_name, timings))
        .then(res => {
          setLogistica(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [dashboardType, startDate, endDate]);

    return {
      logistica_loading: status === STATUS.LOADING,
      logistica_error: status === STATUS.ERROR ? status : '',
      logistica_data: logistica,
    };
  },
  GetUserOptions() {
    const [user_options, setUserOptions] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUserOptions())
        .then(res => {
          setUserOptions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);
    return {
      user_options_loading: status === STATUS.LOADING,
      user_options_error: status === STATUS.ERROR ? status : '',
      user_options: user_options,
    };
  },
  GetAlerts(searchQuery, refetch) {
    const [alerts, setAlerts] = useState({
      totalItems: 0,
      alerts: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAlerts(searchQuery))
        .then(res => {
          setAlerts(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch, searchQuery?.page, searchQuery?.pageSize]);
    return {
      alerts_loading: status === STATUS.LOADING,
      alerts_error: status === STATUS.ERROR ? status : '',
      alerts_data: alerts,
    };
  },
  GetReports(searchQuery, refetch) {
    const [reports, setReports] = useState({
      logistica_chart: [],
      logistica_table: [],
      embalaje_chart_1: [],
      embalaje_chart_2: [],
      shifts: [],
      especiales_chart: [],
      especiales_table: [],
      causas_parada: [],
      cuadros_por_linea: [],
      utilizacion_disponble_por_liena: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);

    useEffect(() => {
      if (
        !!searchQuery?.filterMachine?.length &&
        !!searchQuery?.filterMachine2.length &&
        !!searchQuery?.filterTurno?.length &&
        !!searchQuery?.startDate &&
        !!searchQuery?.endDate
      ) {
        setStatus(STATUS.LOADING);
        cancellablePromise(this.getReports(searchQuery))
          .then(res => {
            setReports(() => res);
            setStatus(STATUS.SUCCESS);
          })
          .catch(() => setStatus(STATUS.ERROR));
      }
    }, [
      searchQuery?.filterMachine?.length,
      searchQuery?.filterMachine2.length,
      searchQuery?.filterTurno?.length,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);
    return {
      reports_loading: status === STATUS.LOADING,
      reports_error: status === STATUS.ERROR ? status : '',
      reports_data: reports,
    };
  },
  async login(email, password, demo_user_mail = '') {
    let res = await Fetch.post(`${this._url}/login`, {
      email,
      password,
      demo_user_mail,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      setCookie('admin_token', res.token);
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async register(payload) {
    let res = await Fetch.post(`${this._url}/register`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async logout() {
    const res = await Fetch.post(`${this._url}/logout`, {});
    if (res.status >= 200 && res.status < 300) {
      return true;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getCurrentUser() {
    const res = await Fetch.get(`${this._url}/me`);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getUsers({ page = 1, itemsPerPage = 10, startDate = '', endDate = '', searchText = '', filterText = '' }) {
    let res = await Fetch.get(
      `${this._url}/users?page=${page}&itemsPerPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        users: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async deleteUser(id) {
    let res = await Fetch.delete(`${this._url}/users/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async updateUser(id, payload) {
    let res = await Fetch.put(`${this._url}/users/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async createUser(payload) {
    let res = await Fetch.post(`${this._url}/users`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getPermissions({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    parentOnly = false,
    filterPermission = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-permission?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterText=${filterPermission}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&parentOnly=${parentOnly}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      if (parentOnly) {
        return {
          permissionStatus: res.items.map(({ can }) => ({ label: can.split('.')[0], value: can.split('.')[0] })),
        };
      }
      return {
        permissions: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createRole(payload) {
    let res = await Fetch.post(`${this._url}/create-role`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateRole(id, payload) {
    let res = await Fetch.put(`${this._url}/update-role/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteRole(id) {
    let res = await Fetch.delete(`${this._url}/delete-role/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createPermission(payload) {
    let res = await Fetch.post(`${this._url}/create-permission`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updatePermission(id, payload) {
    let res = await Fetch.put(`${this._url}/update-permission/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deletePermission(id) {
    let res = await Fetch.delete(`${this._url}/delete-permission/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async restorePermissions() {
    let res = await Fetch.post(`${this._url}/restore-permissions`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async rolesFiltersOptions({ getAll = true }) {
    let res = await Fetch.get(`${this._url}/get-all-role?getAll=${getAll}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res.items.map(({ type }) => ({ label: type, value: type })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getRoles({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterRoles = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-role?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        roles: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getComments({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/comments?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        comments: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getEspeciales({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/especiales?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        especiales: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getEmbalajes({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/embalaje?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        embalaje: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createComment(payload) {
    let res = await Fetch.post(`${this._url}/comment`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateComment(id, payload) {
    let res = await Fetch.put(`${this._url}/comment/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteComment(id) {
    let res = await Fetch.delete(`${this._url}/comment/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createEspeciales(payload) {
    let res = await Fetch.post(`${this._url}/especiales`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateEspeciales(id, payload) {
    let res = await Fetch.put(`${this._url}/especiales/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteEspeciales(id) {
    let res = await Fetch.delete(`${this._url}/especiales/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createEmbalaje(payload) {
    let res = await Fetch.post(`${this._url}/embalaje`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateEmbalaje(id, payload) {
    let res = await Fetch.put(`${this._url}/embalaje/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteEmbalaje(id) {
    let res = await Fetch.delete(`${this._url}/embalaje/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getShifts({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    filterMachine = { value: '' },
    filterTurno = { value: '' },
  }) {
    if (filterMachine.value === 'all') {
      filterMachine.value = '';
    }
    if (filterTurno.value === 'all') {
      filterTurno.value = '';
    }
    let res = await Fetch.get(
      `${this._url}/shifts?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&filterMachine=${filterMachine.value}&filterTurno=${filterTurno.value}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        shifts: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createShift(payload) {
    let res = await Fetch.post(`${this._url}/shift`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateShift(id, payload) {
    let res = await Fetch.put(`${this._url}/shift/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteShift(id) {
    let res = await Fetch.delete(`${this._url}/shift/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createStoppage(payload) {
    let res = await Fetch.post(`${this._url}/stoppage`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateStoppage(id, payload) {
    let res = await Fetch.put(`${this._url}/stoppage/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteStoppage(id) {
    let res = await Fetch.delete(`${this._url}/stoppage/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getStoppages({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    filterMotive = { value: '' },
    filterMachine = { value: '' },
    filterTurno = { value: '' },
    hideMicro = false,
  }) {
    if (filterMotive.value === 'all') {
      filterMotive.value = '';
    }
    if (filterMachine.value === 'all') {
      filterMachine.value = '';
    }
    if (filterTurno.value === 'all') {
      filterTurno.value = '';
    }

    let res = await Fetch.get(
      `${this._url}/stoppages?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&filterMotive=${filterMotive.value}&filterMachine=${filterMachine.value}&filterTurno=${filterTurno.value}&hideMicro=${hideMicro}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        stoppages: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLiveStoppages(company_name, timings) {
    if (!company_name)
      return {
        stoppages: [],
        totalItems: 0,
      };

    let now = getShiftDateForCompany(company_name, timings);
    if (company_name.toLowerCase() === 'demo') {
      now = '2023-11-27';
    }
    let res = await Fetch.get(`${this._url}/go/get-stoppages?empresa=${company_name}&date=${now}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        stoppages: res,
        totalItems: res.length,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLiveShifts(company_name, timings) {
    if (!company_name)
      return {
        stoppages: [],
        totalItems: 0,
      };
    let now = getShiftDateForCompany(company_name, timings);
    if (company_name.toLowerCase() === 'demo') {
      now = '2023-11-27';
    }
    let res = await Fetch.get(`${this._url}/go/get-shifts?empresa=${company_name}&date=${now}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        shifts: res,
        totalItems: res.length,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateLiveStoppage(payload) {
    let res = await Fetch.post(`${this._url}/go/update-stoppages`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        message: res.message,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getOptions(type = '') {
    let res = await Fetch.get(`${this._url}/stoppage-options?type=${type}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async resetPassword(data) {
    let res = await Fetch.post(`${this._url}/reset-password`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardMachines(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-machines?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardMachinesForShifts(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-machines-for-shifts?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardEmbalajes(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-embalaje?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getDashboardComments(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-comments?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateCompanyDetails(id, payload) {
    if (!payload.email_alert) {
      payload.email_alert = false;
    }
    let res = await Fetch.customAuthPut(`${this._url}/company-details/${id}`, payload, 'qwertyuiop');
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getDashboardStoppages(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-top-stoppages?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardStoppagesForShifts(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-top-stoppages-for-shifts?dashboardType=${dashboardType}&startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardEspeciales(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-especiales?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getCompanies({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/companies?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        companies: res.items,
        totalItems: res.totalItems,
      };
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async deleteCompany(id) {
    let res = await Fetch.delete(`${this._url}/companies/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getMachineOptions() {
    let res = await Fetch.get(`${this._url}/machine-options`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async deleteMultipleShifts(ids) {
    let res = await Fetch.post(`${this._url}/delete-multiple-shifts`, ids);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async deleteMultipleStoppages(ids) {
    let res = await Fetch.post(`${this._url}/delete-multiple-stoppages`, ids);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async updatePassword(payload) {
    let res = await Fetch.put(`${this._url}/update-password`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message);
  },
  async getLOGS({ page = 1, pageSize = 10, startDate = '', endDate = '', filterUsers = '', filterRoles = '' }) {
    let res = await Fetch.get(
      `${this._url}/logs?page=${page}&perPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&filterUsers=${filterUsers}&filterRoles=${filterRoles}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        logs: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async logsFiltersOptions() {
    let res = await Fetch.get(`${this._url}/admin-options`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return { options: res };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLogistica({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/logistica?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        logistica: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteLogistica(id) {
    let res = await Fetch.delete(`${this._url}/logistica/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async createLogistica(payload) {
    let res = await Fetch.post(`${this._url}/logistica`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async updateLogistica(id, payload) {
    let res = await Fetch.put(`${this._url}/logistica/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getDashboardlogistica(dashboardType, startDate, endDate, company_name, timings) {
    if (dashboardType === 'day') {
      startDate = getShiftDateForCompany(company_name, timings);
    }
    let res = await Fetch.get(
      `${this._url}/dashboard-logistica?dashboardType=${dashboardType}&startDate=${startDate ?? ''}&endDate=${
        endDate ?? ''
      }`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getUserOptions() {
    let res = await Fetch.get(`${this._url}/user-options`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async addAlert(payload) {
    let res = await Fetch.post(`${this._url}/alert`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getAlerts({ page = 1, pageSize = 10 }) {
    let res = await Fetch.get(`${this._url}/alerts?page=${page}&perPage=${pageSize}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        alerts: res.items,
        totalItems: res.totalItems,
      };
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async deleteAlert(id) {
    let res = await Fetch.delete(`${this._url}/alert/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async updateAlert(id, payload) {
    let res = await Fetch.put(`${this._url}/alert/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportUsers(searchQuery) {
    let res = await Fetch.get(
      `${this._url}/export-users?searchText=${searchQuery.searchText}&startDate=${
        searchQuery.startDate ?? ''
      }&endDate=${searchQuery.endDate ?? ''}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportComments(searchQuery) {
    let res = await Fetch.get(
      `${this._url}/export-comments?searchText=${searchQuery.searchText}&startDate=${
        searchQuery.startDate ?? ''
      }&endDate=${searchQuery.endDate ?? ''}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportEspeciales(searchQuery) {
    let res = await Fetch.get(
      `${this._url}/export-especiales?searchText=${searchQuery.searchText}&startDate=${
        searchQuery.startDate ?? ''
      }&endDate=${searchQuery.endDate ?? ''}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportEmbalaje(searchQuery) {
    let res = await Fetch.get(
      `${this._url}/export-embalajes?searchText=${searchQuery.searchText}&startDate=${
        searchQuery.startDate ?? ''
      }&endDate=${searchQuery.endDate ?? ''}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportShifts({
    searchText = '',
    startDate = '',
    endDate = '',
    filterMachine = { value: '' },
    filterTurno = { value: '' },
  }) {
    let res = await Fetch.get(
      `${this._url}/export-shifts?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterMachine=${filterMachine.value}&filterTurno=${filterTurno.value}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportStoppages({
    searchText = '',
    startDate = '',
    endDate = '',
    filterMotive = { value: '' },
    filterMachine = { value: '' },
    filterTurno = { value: '' },
  }) {
    let res = await Fetch.get(
      `${this._url}/export-stoppages?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterMotive=${filterMotive.value}&filterMachine=${filterMachine.value}&filterTurno=${filterTurno.value}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportRoles({ searchText = '', filterRoles = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/export-roles?searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportPermissions({ searchText = '', startDate = '', endDate = '', filterPermission = '' }) {
    let res = await Fetch.get(
      `${this._url}/export-permissions?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterPermission=${filterPermission}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportLogs({ startDate = '', endDate = '', filterUsers = '', filterRoles = '' }) {
    let res = await Fetch.get(
      `${this._url}/export-logs?startDate=${startDate}&endDate=${endDate}&filterUsers=${filterUsers}&filterRoles=${filterRoles}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async exportLogistica({ searchText = '', startDate = '', endDate = '' }) {
    let res = await Fetch.get(
      `${this._url}/export-logistica?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.blob();
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
  async getReports({ startDate = '', endDate = '', filterMachine = [], filterTurno = [], filterMachine2 = [] }) {
    let res = await Fetch.post(`${this._url}/reports`, {
      startDate,
      endDate,
      filterMachine,
      filterTurno,
      filterMachine2,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
};

export default apis;

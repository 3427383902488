/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import API from 'services/api';
import { AuthContext } from 'context/authContext';
import GridCol from 'components/atoms/GridCol';
import Select from 'components/atoms/Select';
import { useContextHook } from 'use-context-hook';

function UserForm({ user, onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    reFetch,
    user: {
      company_id: { company_name, _id: company_id, ..._ },
      ...rest
    },
  } = useContextHook(AuthContext, {
    reFetch: 1,
    user: 1,
  });

  const { roles_data } = API.GetRoles({ getAll: true });
  const { machines_data: machine_options } = API.GetMachineOptions();
  const roles = useMemo(
    () => roles_data.roles.map(({ _id, type }) => ({ value: _id, label: type })),
    [roles_data, user],
  );

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        ...user,
        roles: user.roles.map(({ _id, type }) => ({ value: _id, label: type })),
        machines: user?.machines?.length
          ? user?.machines?.map(name => ({ value: name, label: name }))
          : [
              {
                label: 'All',
                value: '',
              },
            ],
      });
      setState({
        ...user,
        role: user.roles.map(({ _id, type }) => ({ value: _id, label: type })),
        machines: user?.machines?.length
          ? user?.machines?.map(name => ({ value: name, label: name }))
          : [
              {
                label: 'All',
                value: '',
              },
            ],
      });
    } else {
      form.setFieldsValue({
        company_id,
        company_name,
        machines: [
          {
            label: 'All',
            value: '',
          },
        ],
      });
      setState({
        company_id,
        company_name,
        machines: [
          {
            label: 'All',
            value: '',
          },
        ],
      });
    }
  }, [user, roles]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      if (user?._id) {
        const allowed = ['first_name', 'last_name', 'roles', 'phone_number', 'machines'];
        Object.keys(data).forEach(key => {
          if (!allowed.includes(key)) delete data[key];
        });
        await API.updateUser(user._id, {
          ...data,
          roles: data?.roles.map(({ value }) => value),
          machines: data?.machines?.map(({ value }) => value).filter(x => x),
        });
      } else
        await API.createUser({
          ...data,
          roles: data?.roles.map(({ value }) => value),
          company_name: company_name,
          company_id: company_id,
          machines: data?.machines?.map(({ value }) => value).filter(x => x),
        });
      Toast({
        type: 'success',
        message: `User ${user?._id ? 'updated' : 'created'} successfully`,
      });
      setLoading(false);
      onClose();
      reFetch();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        if (Object.keys(_).includes('machines')) {
          if (_.machines.length > 1 && _.machines.find(({ value }) => value === '')) {
            _.machines = _.machines.filter(({ value }) => value);
            form.setFieldsValue({ machines: _.machines });
          } else if (_.machines.length === 0) {
            _.machines = [
              {
                label: 'All',
                value: '',
              },
            ];
            form.setFieldsValue({ machines: _.machines });
          }
        }
        setState(__ => ({ ...__, ..._ }));
      }}>
      <Grid lg={24} xl={25}>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="text"
            disabled={user?._id}
            label="Email"
            name="email"
            placeholder="Your Email"
            prefix={<i className="material-icons-outlined">email</i>}
            rules={[{ required: true }]}>
            <Field disabled={user?._id} />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="First Name"
            name="first_name"
            placeholder="Your first name"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="Last Name"
            name="last_name"
            placeholder="Your last name"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="text"
            disabled
            label="Company Name"
            name="company_name"
            placeholder={company_name || 'Your company name'}
            prefix={<i className="material-icons-outlined">add_business</i>}>
            <Field disabled />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="number"
            label="Phone Number"
            name="phone_number"
            placeholder="Your phone number"
            prefix={<i className="material-icons-outlined">phone</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            sm
            options={machine_options}
            isSearchable
            isMulti
            name="machines"
            label="machines"
            placeholder="Machines"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            rules={[
              { required: true, message: 'Select atleast one machine' },
              {
                transform: value => !value?.length,
                message: 'Select at least one machine',
              },
            ]}>
            <Select />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            sm
            options={roles}
            isSearchable
            isMulti
            name="roles"
            label="Role"
            placeholder="Role"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            rules={[
              { required: true, message: 'Select atleast one role' },
              {
                transform: value => !value?.length,
                message: 'Select at least one role',
              },
            ]}>
            <Select />
          </Form.Item>
        </GridCol>
        {!user?._id && (
          <GridCol lg={24} xl={24}>
            <Form.Item
              type="password"
              label="Password"
              name="password"
              placeholder="Your Password"
              prefix={<i className="material-icons-outlined">lock</i>}
              rules={[
                {
                  required: true,
                },
                {
                  password: true,
                },
              ]}>
              <Field />
            </Form.Item>
          </GridCol>
        )}
      </Grid>
      <Button loading={loading} type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}

export default UserForm;

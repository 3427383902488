/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import Compress from 'react-image-file-resizer';

import { DateTime } from 'luxon';
/**
 * Sets a cookie with the given name, value and expiration time.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} days - The number of days until the cookie expires.
 * @returns {boolean} - Returns true if the cookie was successfully set.
 */
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;

  return true;
};

/**
 *
 * @param {*} name
 * @returns
 */
export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Clears a cookie by setting its expiration date to a date in the past.
 * @param {string} name - The name of the cookie to clear.
 * @returns {boolean} - Returns true if the cookie was cleared successfully.
 */
export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
export const capitalize = (str = '') => {
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

/**
 * Returns a Date object based on the input string.
 * If the input string is invalid, returns the current date and time in the Canada/Eastern timezone.
 * @param {string} e - The input string to parse as a date.
 * @returns {Date} - A Date object representing the parsed date.
 */
export const getDateObject = (e = '') => {
  const date = new Date(e);
  date.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date?.getTime())) {
    const x = new Date();
    x.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
    return x;
  }
  return date;
};

/**
 * Converts a given amount to currency format.
 * @param {string|number} amount - The amount to be converted to currency format.
 * @returns {string} The amount in currency format.
 */
export const convertToCurrencyFormat = (amount = '0') =>
  `$ ${Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

/**
 * Shortens a given string to a specified length and appends ellipsis if necessary.
 *
 * @param {string} str - The string to shorten.
 * @param {number} [len=10] - The maximum length of the shortened string.
 * @returns {string} The shortened string.
 */
export const shortenString = (str, len = 10) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

// eslint-disable-next-line no-unused-vars
/**
 * Converts time in seconds to a human-readable format.
 * @param {number} seconds - The time in seconds to be converted.
 * @param {boolean} [dontShowSeconds=true] - Whether to exclude seconds from the result.
 * @returns {string} The time in a human-readable format.
 */
export const timeConverter = (seconds, dontShowSeconds = true) => {
  const days = Math.floor(seconds / (60 * 60 * 24));
  const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
  const mins = Math.floor((seconds % (60 * 60)) / 60);
  const secs = seconds % 60;

  let result = '';
  // if (days > 0) {
  //   result += `${days}d `;
  // }
  if (hours > 0) {
    const tHours = days * 24;
    result += `${Number(hours) + tHours}h `;
  }
  if (mins > 0) {
    result += `${mins}m `;
  }
  if (secs > 0) {
    result += `${secs}s`;
  }
  if (dontShowSeconds && (days > 0 || hours > 0)) {
    result = result.replace(`${secs}s`, '');
  }

  return result.trim();
};

/**
 * Converts content to the specified language.
 * @param {string} content - The content to be converted.
 * @param {string} lang - The language to convert the content to. Default is 'en'.
 * @returns {string} The converted content.
 */
export const LangConverter = (content = '', lang = 'en') => {
  const toLowerCaseKeysObject = obj => {
    const newObj = {};
    for (const key in obj) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };
  const SpanishVocab = {
    'online dashboard': 'Tableros Online',
    dashboard: 'Tableros de Gestión',
    'today stoppages': 'Paradas de Hoy',
    'daily stoppages': 'Paradas de Hoy',
    stoppages: 'Paradas',
    users: 'Usuarios',
    interval: 'Período',
    'create especiales': 'Nuevo Lote',
    'create embalaje': 'Nuevo Lote',
    'create comment': 'Nuevo Comentario',
    'create role': 'Crear Rol',
    'create permission': 'Crear Permiso',
    comments: 'Comentario',
    roles: 'Roles',
    permissions: 'Permisos',
    'no permissions': 'Sin Permisos',
    'update company info': 'Actualizar Información de la Empresa',
    'add user': 'Agregar Usuario',
    'all lines production summary': 'Resumen Producción por Línea',
    'shift summary': 'Resumen Fin de Turno',
    machines: 'Producción por Línea',
    shifts: 'Resumen de Turno',
    search: 'Buscador',
    'date range': 'Rango de Fechas',
    'filter motive': 'Filtrar Motivo',
    'filter machine': 'Filtrar Máquina',
    'filter shift': 'Filtrar Turno',
    'select language': 'Seleccionar Idioma',
    'no data available !!!': 'No hay datos disponibles !!!',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    '6 months': '6 Meses',
    yearly: 'Anual',
    custom: 'Personalizado',
    'click below to try the demo': '¿Quieres probar la demostración?',
    'sign in': 'Inicia Sesión',
    'your email': 'Tu Email',
    password: 'Contraseña',
    'your password': 'Tu Contraseña',
    'forgot password': '¿Olvidaste tu Contraseña?',
    line: 'Línea',
    'shift manager': 'Jefe de turno',
    // 'Incident on Line 2 due to hydraulic fluid spill. It was fixed in 45 minutes and the maintenance team intervened':
    // 'Incidente en Linea 2 por derrame de liquido hidraulico. Se arreglo en 45 minutos y el equipo de mantenimiento intervino',
    'available utilisation': 'Utilización disponible',
    'possible utilisation': 'Utilización posible',
    'total units': 'Total de unidades',
    'std units': 'Unidades std',
    'cycle time': 'Tiempo de ciclo',
    cycle: 'Tiempo de ciclo',
    avg: 'prom',

    std_time_x_unit: 'TC STD',
    'start of shift': 'Inicio de turno',
    'end of shift': 'Fin de turno',
    'active shift': 'Turno activo',
    'shift 1': 'Turno 1',
    'shift 2': 'Turno 2',
    'shift 3': 'Turno 3',
    'shifts()': 'Turnos',
    'real use': 'Uso real',
    'std use': 'Uso std',
    'real ct': 'Tiempo de ciclo real',
    'std ct': 'Tiempo de ciclo std',
    date: 'Fecha',
    parts: 'Unidades',
    shift: 'Turno',
    units: 'Unidades',
    'line 1': 'Línea 1',
    'line 2': 'Línea 2',
    actions: 'Acciones',
    'utilizacion disponible std': 'Utilización Disponible (STD)',
    'utilizacion disponible real': 'Utilización Disponible (Real)',
    'utilizacion posible std': 'Utilización posible (STD)',
    'utilizacion posible real': 'Utilización real (Real)',
    'top stoppages': 'Principales Paradas',
    'turno 1 turno active': 'Turno 1 Activo',
    'turno 2 turno active': 'Turno 2 Activo',
    'turno 3 turno active': 'Turno 3 Activo',

    'turno 1 utilizacion disponible std': 'Turno 1 Utilización disponible std',
    'turno 1 utilizacion disponible real': 'Turno 1 Utilización disponible real',
    'turno 1 utilizacion posible std': 'Turno 1 Utilización posible std',
    'turno 1 utilizacion posible real': 'Turno 1 Utilización posible real',

    'turno 2 utilizacion disponible std': 'Turno 2 Utilización disponible std',
    'turno 2 utilizacion disponible real': 'Turno 2 Utilización disponible real',
    'turno 2 utilizacion posible std': 'Turno 2 Utilización posible std',
    'turno 2 utilizacion posible real': 'Turno 2 Utilización posible real',

    'turno 3 utilizacion disponible std': 'Turno 3 Utilización disponible std',
    'turno 3 utilizacion disponible real': 'Turno 3 Utilización disponible real',
    'turno 3 utilizacion posible std': 'Turno 3 Utilización posible std',
    'turno 3 utilizacion posible real': 'Turno 3 Utilización posible real',
    'select date range': 'Seleccionar rango de fechas',
    'no record found': 'No se encontraron registros',
    showing: 'Mostrando',
    of: 'de',
    results: 'resultados',
    'result per page': 'resultados por página',
    next: 'Siguiente',
    previous: 'Anterior',
    'out of': 'de',
    'notifications for monitored machines are on': 'Las notificaciones para las máquinas monitoreadas están activadas',
    'notifications for monitored machines are off':
      'Las notificaciones para las máquinas monitoreadas están desactivadas',
    'turn on notifications to receive updates about machines that are currently being monitored. make sure to review the list of monitored machines and their shifts below.':
      'Encienda las notificaciones para recibir actualizaciones sobre las máquinas que actualmente están siendo monitoreadas. Asegúrese de revisar la lista de máquinas monitoreadas y sus turnos a continuación.',
    'machine name': 'Nombre de la máquina',
    'are you sure you want to change notification status ?':
      '¿Estás seguro de que quieres cambiar el estado de las notificaciones?',
    settings: 'Ajustes',
    logout: 'Cerrar Sesión',
    'Profile Settings': 'Configuración de Perfil',
    Profile: 'Perfil',
    'First Name': 'Nombre',
    'Last Name': 'Apellido',
    Company: 'Empresa',
    Email: 'Email',
    'User Since': 'Usuario desde',
    'User Role': 'Rol de Usuario',
    'Company Logo': 'Logo de la Empresa',
    'Stoppage Reasons': 'Motivos de Parada',
    'Shifts & Timings': 'Turnos y Horarios',
    'Machines & STD units': 'Máquinas y Unidades STD',
    Monitoring: 'Monitoreo',
    Notifications: 'Notificaciones',
    'current Password': 'Contraseña Actual',
    'New Password': 'Nueva Contraseña',
    'You Don`t Have Permission To Access This , Kindly contact Marco Zaratie for more details':
      'No tienes permiso para acceder a esto, por favor contacta a Marco Zaratie para más detalles',
    'Create Logistics': 'Crear Logística',
    'Enter your email to try the demo': 'Ingresa tu email para probar la demo',
  };
  const englishVocab = {
    diario: 'Daily',
    semanal: 'Weekly',
    mensual: 'Monthly',
    '6 meses': '6 Months',
    anual: 'Yearly',
    personalizado: 'Custom',
    'turno 1': 'Shift 1',
    'turno 2': 'Shift 2',
    'turno 3': 'Shift 3',
    juan: 'John F',
    'linea 1': 'Line 1',
    'linea 2': 'Line 2',
    total_piezas: 'Total Units',
    'pzs std': 'Std Units',
    'utilizacion disponible std': 'Available Utilisation STD',
    'utilizacion disponible real': 'Available Utilisation REAL',
    'utilizacion posible std': 'Possible Utilisation STD',
    'utilizacion posible real': 'Possible Utilisation REAL',
    'tiempo de ciclo real': 'Cycle Time (Real)',
    tiempo_std_x_pieza: 'STD_Time_X_Unit',
    'turno 1 inicio de turno': 'Shift 1: Start of Shift',
    'turno 1 fin de turno': 'Shift 1: End of Shift',
    'turno 2 inicio de turno': 'Shift 2: Start of Shift',
    'turno 2 fin de turno': 'Shift 2: End of Shift',
    'turno 3 inicio de turno': 'Shift 3: Start of Shift',
    'turno 3 fin de turno': 'Shift 3: End of Shift',
    'turno 1 turno active': 'Shift 1: Active Shift',
    'turno 2 turno active': 'Shift 2: Active Shift',
    'turno 3 turno active': 'Shift 3: Active Shift',

    'turno 1 total_piezas': 'Shift 1: Total Units',
    'turno 1 pzs std': 'Shift 1: Std Units',
    'turno 1 utilizacion disponible std': 'Shift 1: Available Utilisation STD',
    'turno 1 utilizacion disponible real': 'Shift 1: Available Utilisation REAL',
    'turno 1 utilizacion posible std': 'Shift 1: Possible Utilisation STD',
    'turno 1 utilizacion posible real': 'Shift 1: Possible Utilisation REAL',
    'turno 1 tiempo de ciclo real': 'Shift 1: Cycle Time (Real)',
    'turno 1 tiempo_std_x_pieza': 'Shift 1: STD_Time_X_Unit',

    'turno 2 total_piezas': 'Shift 2: Total Units',
    'turno 2 pzs std': 'Shift 2: Std Units',
    'turno 2 utilizacion disponible std': 'Shift 2: Available Utilisation STD',
    'turno 2 utilizacion disponible real': 'Shift 2: Available Utilisation REAL',
    'turno 2 utilizacion posible std': 'Shift 2: Possible Utilisation STD',
    'turno 2 utilizacion posible real': 'Shift 2: Possible Utilisation REAL',
    'turno 2 tiempo de ciclo real': 'Shift 2: Cycle Time (Real)',
    'turno 2 tiempo_std_x_pieza': 'Shift 2: STD_Time_X_Unit',

    'turno 3 total_piezas': 'Shift 3: Total Units',
    'turno 3 pzs std': 'Shift 3: Std Units',
    'turno 3 utilizacion disponible std': 'Shift 3: Available Utilisation STD',
    'turno 3 utilizacion disponible real': 'Shift 3: Available Utilisation REAL',
    'turno 3 utilizacion posible std': 'Shift 3: Possible Utilisation STD',
    'turno 3 utilizacion posible real': 'Shift 3: Possible Utilisation REAL',
    'turno 3 tiempo de ciclo real': 'Shift 3: Cycle Time (Real)',
    'turno 3 tiempo_std_x_pieza': 'Shift 3: STD_Time_X_Unit',

    turno: 'Shift',
    'turno fecha': 'Shift Date',
    'fecha y hora': 'Date & Time',
    paradas: 'Stoppages',
    maquina: 'Machine',
    commentario: 'Comment',
    comentario: 'Comment',
    'sin asignar': 'Unassigned',
  };
  if (lang === 'sp' && content) {
    return toLowerCaseKeysObject(SpanishVocab)[content.trim().toLowerCase().replace(/-/, ' ')] ?? content;
  }
  if (lang === 'en' && content) {
    return toLowerCaseKeysObject(englishVocab)[content.trim().toLowerCase().replace(/-/, ' ')] ?? content;
  }
  return content;
};

// eslint-disable-next-line no-unused-vars
export function getShiftDateForCompany(company_name = '', shifts_info = {}) {
  const companyShiftTimings = shifts_info.reduce((a, b) => {
    if (!a[b.EMPRESA.toLowerCase()]) {
      a[b.EMPRESA.toLowerCase()] = {};
    }
    if (!a[b.EMPRESA.toLowerCase()][b.TURNO]) {
      a[b.EMPRESA.toLowerCase()][b.TURNO] = {};
    }
    a[b.EMPRESA.toLowerCase()][b.TURNO] = {
      start: b.INICIO,
      end: b.FIN,
    };
    return a;
  }, {});
  const shiftTiming = companyShiftTimings[company_name.toLowerCase()];
  const dateTimeBuenosAires = DateTime.now().setZone('America/Argentina/Buenos_Aires');
  let shiftStartDate = dateTimeBuenosAires.set({
    hour: shiftTiming['TURNO 1'].start.split(':')[0],
    minute: shiftTiming['TURNO 1'].start.split(':')[1],
    second: 0,
  });
  if (dateTimeBuenosAires.diff(shiftStartDate, 'seconds') < 0) {
    shiftStartDate = dateTimeBuenosAires.minus({ days: 1 });
  }
  return shiftStartDate.toFormat('yyyy-MM-dd');
}
export function isoToArgentinianDate(iso = '', format = 'yyyy-MM-dd HH:mm:ss') {
  const date = DateTime.fromISO(iso).setZone('America/Argentina/Buenos_Aires');
  return date.toFormat(format);
}

export const compressImage = (file, type = 'PNG ') =>
  new Promise(resolve => {
    Compress.imageFileResizer(
      file,
      255,
      90,
      type,
      100,
      0,
      uri => {
        resolve(uri);
      },
      'base64',
    );
  });
